.single-yearendsummary {
  .main {
    @include media-breakpoint-up(lg) {
      max-width:95%;
    }
    @include media-breakpoint-up(xxxl) {
      max-width:1800px;
    }
  }
}
.yearend-hero {
  @include media-breakpoint-up(lg) {
    height:100vh;
    border:30px solid #fff;
  }
  
  .yearend-bg {
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
  }
  
  .yearend-top-row {
    @include media-breakpoint-up(lg) {
      height:40vh;
      border-bottom:15px solid #fff;
    }
  }
  
  .yearend-bottom-row {
    @include media-breakpoint-up(lg) {
      height:60vh;
      border-top:15px solid #fff;
    }
    .yearend-hero-logo {
      img {
        max-height:125px;
        @include media-breakpoint-up(xxxl) {
          max-height:195px;
        }
      }
    }
  }
  
  .yearend-hero-bottom-content {
    text-shadow: 0 0 80px rgba(0,0,0,.5);
  }
  
  .yearend-hero-bottom-subheading {
    letter-spacing:4px;
  }
  
  .yearend-hero-bottom-heading {
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
    span {
      text-shadow: 0 0 80px rgba(0,0,0,.5);
  
  /* Clip Background Image */
  
      background: url(https://seoanalyticsreporting.com/media/range-stripes-white-trans-2.png) repeat-y;
      -webkit-background-clip: text;
      background-clip: text;
  
  /* Animate Background Image */
  
      -webkit-text-fill-color: transparent;
      -webkit-animation: aitf 80s linear infinite;
  
  /* Activate hardware acceleration for smoother animations */
  
      -webkit-transform: translate3d(0,0,0);
      -webkit-backface-visibility: hidden;
  
    }
  }
  
  /* Animate Background Image */
  
  @-webkit-keyframes aitf {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
  }
}
