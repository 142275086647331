.analytics-header {
  .analytics-header-content {
    padding:15px;
    margin:15px;
    background: rgba(255,255,255,.85);
    -webkit-backdrop-filter: saturate(180%) blur(8px);
    backdrop-filter: saturate(180%) blur(8px);
    border-radius:2px;
    @include media-breakpoint-up(lg) {
      padding:45px;
      margin:45px;
    }
    .analytics-range-logo {
      &:after {
        content:'';
        height:65%;
        width:1px;
        background:rgba(0,0,0,0.3);
        position:absolute;
        right:0;
        @include media-breakpoint-down(lg) {
          display:none;
        }
      }
    }
    .analytics-header-logo {
      max-width:70%;
      @include media-breakpoint-up(lg) {
        max-width:90%;
      }
    }
  }
}

.mouse_scroll {
	display: block;
	margin: 0 auto;
	width: 24px;
	height: 100px;
}


.m_scroll_arrows
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.unu
{
  margin-top: 1px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

.trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;
  
  
  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid #000;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;
  
  height: 4px;
  width: 4px;
  border: 2px solid #000;
  -webkit-border-radius: 8px;
          border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

.analytics-data-slide {
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  .data-slide-header {
    display:none;
    position:absolute;
    top:0;
    right:40px;
    img {
      max-width:125px;
    }
    @include media-breakpoint-up(lg) {
      display:flex;
    }
  }
  .data-slide-row {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: stretch;
    .data-slide-col {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: stretch;
      .data-slide-content {
        padding:15px;
        margin:15px;
        @include media-breakpoint-up(lg) {
          padding:45px;
          margin:45px;
        }
        p {
          width:100%;
        }
        img {
          // margin-top:20px;
          // margin-bottom:20px;
          margin-left:auto;
          margin-right:auto;
          display:flex;
        }
        .split-column-section {
          .split-column-col {
            p {
              &:last-of-type {
                margin-bottom:0;
              }
            }
          }
        }
        .col-slide-image {
          @include media-breakpoint-up(lg) {
            max-width:90%;
            border:7px solid #f2f2f2;
          }
        }
      }
    }
  }
}
